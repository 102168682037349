import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "riac-homepage-v2.firebaseapp.com",
    projectId: "riac-homepage-v2",
    storageBucket: "riac-homepage-v2.appspot.com",
    messagingSenderId: "885897172836",
    appId: "1:885897172836:web:895ee43d845336e42a0c71",
    measurementId: "G-MJJGDK6C40",
};

const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const storage = getStorage(app);

const db = getFirestore(app);

export { db, storage };

//Fixa env variabler
