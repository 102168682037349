import { useContext, useEffect } from "react";
import { UserContext } from "../App";
import { useNavigate } from "react-router-dom";

export default function PermissionGuard(permissionLevelRequired) {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (user.permission_level_id < permissionLevelRequired) {
            console.log("Access denied.");
            navigate("/stugan");
        }
    }, [navigate, user, permissionLevelRequired]);
}
