import React from "react";
import "./css/register.css";

function Register() {
  return <div>Register</div>;
}

export default Register;

/* To create an account you need to have an invitation code, thats stored in the firebase docs. These can be created by the superadmin.  */
