import { db } from "../firebase.config";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";

async function getDocumentById(collectionName, documentId) {
    try {
        // Reference to the specific document
        const docRef = doc(db, collectionName, documentId);

        // Fetch the document
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            // Document data will be in docSnap.data()
            return docSnap.data();
        } else {
            console.log("No such document!");
            return null;
        }
    } catch (error) {
        console.error("Error getting document:", error);
    }
}

async function getAllDocuments(collectionName) {
    try {
        const querySnapshot = await getDocs(collection(db, collectionName));
        const documents = [];
        querySnapshot.forEach((doc) => {
            documents.push({ id: doc.id, ...doc.data() });
        });
        return documents;
    } catch (error) {
        console.error("Error getting documents: ", error);
        throw error;
    }
}

export { getDocumentById, getAllDocuments };
