import React, { useEffect, useState } from "react";
import "./css/login.css";
import { useNavigate } from "react-router-dom";

function Login(props) {
    const [inputUsername, setInputUsername] = useState("");
    const [inputPassword, setInputPassword] = useState("");
    const [user, setUser] = useState();

    const login = (event) => {
        event.preventDefault();
        if (inputUsername.length > 0 && inputPassword.length > 0) {
            const result = async (username, password) => {
                const res = await props.login(username, password);
                setUser(res);
            };
            result(inputUsername, inputPassword);

            setInputUsername("");
            setInputPassword("");
        }
    };
    const navigate = useNavigate();
    useEffect(() => {
        if (user != null) {
            if (user.is_logged_in) {
                navigate("/stugan");
            }
        }
    }, [navigate, user]);

    return (
        <div className="login-page">
            <form onSubmit={login}>
                <div className="input-box">
                    <div className="input-area">
                        <label htmlFor="login-username">Username:</label>
                        <input
                            type="text"
                            id="login-username"
                            value={inputUsername}
                            onChange={(event) =>
                                setInputUsername(event.target.value)
                            }
                        />
                    </div>
                    <div className="input-area">
                        <label htmlFor="login-password">Password:</label>
                        <input
                            type="password"
                            id="login-password"
                            value={inputPassword}
                            onChange={(event) =>
                                setInputPassword(event.target.value)
                            }
                        />
                    </div>
                </div>
                <div className="login-box">
                    <button className="login-button">Login</button>
                </div>
            </form>
        </div>
    );
}

export default Login;
