import React, { useEffect, useState } from "react";
import "./css/navLoggedIn.css";
import { Link, useLocation } from "react-router-dom";
import blank_pp from "../img/blank-profile-picture.png";
import { getProfilePicture } from "../utility/UsersFunctions";

function NavLoggedIn(props) {
    // Get profile picture
    const [profilePicture, setProfilePicture] = useState(blank_pp);
    const location = useLocation();

    useEffect(() => {
        const updateProfilePictureVar = async () => {
            const downloadedPP = await getProfilePicture(props.username);
            if (downloadedPP != null && downloadedPP.length > 5) {
                setProfilePicture(downloadedPP);
            }
        };
        updateProfilePictureVar();
    }, [props, location]);

    return (
        <div className="logged-in">
            <Link to="/profile" className="link">
                <div className="username">{props.username}</div>
                <div className="profile-picture">
                    <img src={profilePicture} alt="Profile" />
                </div>
            </Link>
        </div>
    );
}

export default NavLoggedIn;
