import React from "react";
import { Link } from "react-router-dom";
import "./css/navNotLoggedIn.css";

function NavNotLoggedIn() {
  return (
    <div className="not-logged-in">
      <Link to="register">
        <div className="create-account">Create An Account</div>
      </Link>
      <Link to="login">
        <div className="login">Login</div>
      </Link>
    </div>
  );
}

export default NavNotLoggedIn;
