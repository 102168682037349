import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavLoggedIn from "./components/NavLoggedIn";
import NavNotLoggedIn from "./components/NavNotLoggedIn";
import "./nav.css";
function Nav(props) {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!props.user.is_logged_in) {
            navigate("/login");
        } else if (location.pathname === "/login") {
            navigate("/stugan");
        }
    }, [navigate, props, location.pathname]);

    return (
        <div className="nav">
            <Link to="/stugan">
                <div className="nav-logo">RIAC</div>
            </Link>
            <div className="account-info">
                {props.user.is_logged_in ? (
                    <NavLoggedIn
                        username={props.user.username}
                        profilePictureSrc={props.user.profile_picture_path}
                    />
                ) : (
                    <NavNotLoggedIn />
                )}
            </div>
        </div>
    );
}

export default Nav;
