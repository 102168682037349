import React, { useContext, useState } from "react";
import { UserContext } from "../App";
import "./css/adminFunctions.css";
import { createUser } from "../utility/UsersFunctions";

function AdminFunctions(props) {
    const { user } = useContext(UserContext);

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [permissionLevelId, setPermissionLevelId] = useState(1);
    const [password, setPassword] = useState("");

    const createUserAsAdmin = async () => {
        if (user.permission_level_id === 3) {
            if (
                await createUser(
                    username,
                    firstName,
                    lastName,
                    email,
                    password,
                    permissionLevelId
                )
            ) {
                alert("User created successfully!");
            } else {
                alert("User creation failed.");
            }
        } else {
            console.log("Not autorized!");
        }
        /*
        setUsername("");
        setEmail("");
        setFirstName("");
        setLastName("");
        setPermissionLevelId("");
        setPassword("");
		*/
    };

    return (
        <div className={"admin-functions " + props.className}>
            <div className="create-user-title">Create user</div>
            <div className="form-container">
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(event) => {
                        setUsername(event.target.value);
                    }}
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                    }}
                />
                <input
                    type="text"
                    placeholder="First name"
                    value={firstName}
                    onChange={(event) => {
                        setFirstName(event.target.value);
                    }}
                />
                <input
                    type="text"
                    placeholder="Last name"
                    value={lastName}
                    onChange={(event) => {
                        setLastName(event.target.value);
                    }}
                />
                <input
                    type="number"
                    pattern="[1-3]"
                    placeholder="Permission level"
                    value={permissionLevelId}
                    onChange={(event) => {
                        setPermissionLevelId(event.target.value);
                    }}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(event) => {
                        setPassword(event.target.value);
                    }}
                />
                <button onClick={createUserAsAdmin}>Skapa användare</button>
            </div>
        </div>
    );
}

export default AdminFunctions;
