import React from "react";
import "./css/card.css";
import { Link } from "react-router-dom";

function Card(props) {
  return (
    <div className="card">
      <div>{props.title}</div>
      <div className="link">
        <Link to={props.link}>{props.title}</Link>
      </div>
    </div>
  );
}

export default Card;
