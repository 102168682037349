import { db } from "../firebase.config";
import Cookies from "js-cookie";

import {
    collection,
    doc,
    getDocs,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import { Sha1 } from "@firebase/util";

// Token lifetime in seconds
const TOKEN_LIFETIME = 10800;

const userCollectionRef = collection(db, "users");

async function authorize(username, password) {
    const userResult = await getUser(username);

    const sha1 = new Sha1();
    sha1.update(password + process.env.REACT_APP_PASSWORD_SALT);
    const hashBytes = sha1.digest();
    const hashString = hashBytes
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");

    if (
        hashString === userResult.password &&
        username === userResult.username
    ) {
        userResult.is_logged_in = true;
        const token = getNewToken(username);
        await updateTokenDb(username, token);
        updateTokenLocal(token);
        return userResult;
    } else {
        console.log("NO");
    }
}

function getNewToken(username) {
    const sha1 = new Sha1();
    sha1.update(Date.now() + process.env.REACT_APP_TOKEN_SALT + username);
    const hashBytes = sha1.digest();
    return hashBytes.map((b) => b.toString(16).padStart(2, "0")).join("");
}

async function updateTokenDb(username, token) {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("username", "==", username));

    try {
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.log("No matching documents.");
            return;
        }

        const docRef = querySnapshot.docs[0].ref;

        await updateDoc(docRef, {
            token: token,
            token_datetime: serverTimestamp(),
        });

        console.log("Document successfully updated!");
    } catch (error) {
        console.error("Error updating document: ", error);
    }
}

function updateTokenLocal(token) {
    Cookies.set("token", token, { expires: 1 });
}

async function getUser(username) {
    const userQuery = query(
        userCollectionRef,
        where("username", "==", username)
    );
    const userQueryResult = await getDocs(userQuery);
    // Early return if username doesn't exist
    if (userQueryResult.empty) {
        return;
    }
    const userResult = {
        ...userQueryResult.docs[0].data(),
        id: userQueryResult.docs[0].id,
    };
    return userResult;
}

function getTokenFromLocal() {
    return Cookies.get("token");
}

const tokenIsValid = async (setUser) => {
    const usersRef = collection(db, "users");
    if (getTokenFromLocal() == null) {
        return false;
    }
    const q = query(usersRef, where("token", "==", getTokenFromLocal()));

    try {
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.log("No matching documents.");
            return false;
        }

        const userResult = {
            ...querySnapshot.docs[0].data(),
            id: querySnapshot.docs[0].id,
        };
        const token = userResult.token;

        if (
            token === getTokenFromLocal() &&
            userResult.token_datetime.seconds + TOKEN_LIFETIME >=
                getCurrentTimestampInSeconds()
        ) {
            userResult.is_logged_in = true;
            setUser(userResult);
            return true;
        }
    } catch (error) {
        console.error("Error retrieving token: ", error);
    }
    return false;
};

const getCurrentTimestampInSeconds = () => {
    return Math.floor(Date.now() / 1000);
};

async function getProfilePicture(username) {
    if (username == null) {
        return;
    }
    const user = await getUser(username);
    if (user != null && user.profile_picture_path != null) {
        return user.profile_picture_path;
    }
    return null;
}

async function setProfilePicture(username, picturePath) {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("username", "==", username));

    try {
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.log("No matching documents.");
            return;
        }

        const docRef = querySnapshot.docs[0].ref;

        await updateDoc(docRef, {
            profile_picture_path: picturePath,
        });

        console.log("Document successfully updated!");
    } catch (error) {
        console.error("Error updating document: ", error);
    }
}

async function createUser(
    username,
    firstName,
    lastName,
    email,
    password,
    permissionLevelId
) {
    try {
        const sha1 = new Sha1();
        sha1.update(password + process.env.REACT_APP_PASSWORD_SALT);
        const hashBytes = sha1.digest();
        const realPassword = hashBytes
            .map((b) => b.toString(16).padStart(2, "0"))
            .join("");

        await setDoc(doc(db, "users", username), {
            username: username,
            first_name: firstName,
            last_name: lastName,
            password: realPassword,
            permission_level_id: parseInt(permissionLevelId),
            email: email,
            profile_picture_path: "",
            token: "",
            token_datetime: 0,
        });
        return true;
    } catch (error) {
        console.error("Error writing document: ", error);
        return false;
    }
}

export {
    authorize,
    tokenIsValid,
    getProfilePicture,
    setProfilePicture,
    createUser,
};
