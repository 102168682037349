import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { authorize, tokenIsValid } from "./utility/UsersFunctions";
import Nav from "./Nav";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Stugan from "./pages/Stugan";
import Booking from "./pages/Booking";
import NewGuide from "./pages/NewGuide";
import Profile from "./pages/Profile";

export const UserContext = React.createContext({});

function App() {
    const [user, setUser] = useState("Not logged in!");
    const value = { user, setUser };
    const login = async (username, password) => {
        const userRes = await authorize(username, password);
        if (userRes != null) {
            setUser(userRes);
            return userRes;
        }
    };

    // Check token
    useEffect(() => {
        tokenIsValid(setUser);
    }, []);

    return (
        <UserContext.Provider value={value}>
            <BrowserRouter>
                <Nav user={user} />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login login={login} />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/stugan" element={<Stugan />} />
                    <Route path="/stugan/booking" element={<Booking />} />
                    <Route path="/stugan/new-guide" element={<NewGuide />} />
                    <Route path="/profile" element={<Profile />} />
                </Routes>
            </BrowserRouter>
        </UserContext.Provider>
    );
}

export default App;
