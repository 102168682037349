import Card from "../components/Card";
import ScrollPanel from "../components/ScrollPanel";
import "./css/home.css";

function Home() {
    return (
        <div>
            <ScrollPanel>
                <Card title="Stugan" picture="" link="stugan" />
                <Card title="Vacay" picture="" link="vacay" />{" "}
                {/*link to vacay */}
            </ScrollPanel>
        </div>
    );
}

export default Home;
