import React from "react";
import Markdown from "markdown-to-jsx";
import "./css/guide.css";

function Guide(props) {
    // TODO: add markdown formatter

    /*saveNewGuide(
        "codeTest1",
        "### Hejsan\n\nDetta är ett test av att spara **markdown** via *kod*."
    ); FUNKAR!! */
    return (
        <div id={props.id} className="sec">
            <div className="sec-title">{props.title}</div>
            <div className="sec-body">
                <Markdown
                    options={{
                        forceBlock: true,
                    }}
                >
                    {props.body}
                </Markdown>
            </div>
        </div>
    );
}

export default Guide;
