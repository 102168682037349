import React from "react";
import "./css/copyButton.css";
import copyIcon from "../img/copy-icon.svg";

const CopyButton = ({ textToCopy }) => {
    const copyToClipboard = () => {
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {})
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    };

    return (
        <button onClick={copyToClipboard} className="copy-btn">
            <img src={copyIcon} alt="COPY" />
        </button>
    );
};

export default CopyButton;
