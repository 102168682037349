import React, { useContext, useEffect, useState } from "react";
import "./css/stugan.css";
import { getAllGuides } from "../utility/GuideFunctions";
import Guide from "../components/Guide";
import { Link } from "react-router-dom";
//import Booking from "./Booking";
//import Home from "./Home";
import { UserContext } from "../App";

function Stugan() {
    const { user } = useContext(UserContext);

    const [guidesData, setGuidesData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchGuide = async () => {
            try {
                const data = await getAllGuides();
                setGuidesData(data);
            } catch (error) {
                console.error("Error fetching guide:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchGuide();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!guidesData) {
        return <p>No guide found!</p>;
    }

    const guidesList = guidesData.map((guide) => (
        <Guide
            key={guide.id}
            id={guide.id}
            title={guide.title}
            body={guide.body}
        />
    ));

    const tableOfContents = guidesData.map((guide) => (
        <div key={guide.id}>
            <a href={"#" + guide.id}>{guide.title}</a>
        </div>
    ));

    let addNewGuideLink;
    if (user.permission_level_id >= 2) {
        // User has permission to add new guides
        addNewGuideLink = (
            <div className="new-guide">
                <Link to={"new-guide"} className="new-guide-link">
                    <div className="new-guide-title">Ny guide</div>
                </Link>
            </div>
        );
    }

    return (
        <div className="stugan-main">
            <div className="header">
                <div className="title">
                    <span>Stugan</span>
                </div>

                <div className="second-row">
                    <div className="table-of-contents">
                        <div className="content">Innehåll</div>
                        {tableOfContents}
                    </div>

                    <div className="booking">
                        <Link to={"booking"} className="booking-link">
                            <div className="booking-title">Bokning</div>
                        </Link>
                    </div>
                </div>
                {addNewGuideLink}
            </div>
            <div className="body">{guidesList}</div>
        </div>
    );
}

export default Stugan;

/*  

Stugan - Information om stugan 
Lediga dagar - Alla i släkten kan lägga in sina semesterdagar och så räknar sidan ut vilka dagar som flest har semester på.

*/
