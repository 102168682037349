import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../App";
import blank_pp from "../img/blank-profile-picture.png";
import "./css/profile.css";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase.config";
import {
    getProfilePicture,
    setProfilePicture,
} from "../utility/UsersFunctions";
import AdminFunctions from "../components/AdminFunctions";

function Profile() {
    const [profilePictureLocal, setProfilePictureLocal] = useState(blank_pp);
    const [profilePictureUploaded, setProfilePictureUploaded] = useState(false);
    const { user } = useContext(UserContext);

    const getAdminDiv = () => {
        if (user.permission_level_id === 3) {
            return <AdminFunctions className="sub" />;
        }
        return <></>;
    };

    const adminDiv = getAdminDiv();

    useEffect(() => {
        const updateProfilePictureVar = async () => {
            const downloadedPP = await getProfilePicture(user.username);
            if (
                !profilePictureUploaded &&
                downloadedPP != null &&
                downloadedPP.length > 5
            ) {
                setProfilePictureLocal(downloadedPP);
            }
        };
        updateProfilePictureVar();
    }, [user.logged_in, profilePictureUploaded, user.username]);

    const permissionLevelText = () => {
        switch (user.permission_level_id) {
            case 1:
                return "User";
            case 2:
                return "Admin";
            case 3:
                return "Super-Admin";
            default:
                return "No role";
        }
    };

    const fileInputRef = useRef(null);

    // Function to handle image click
    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    // Function to handle file change
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const storageRef = ref(storage, `profile-pictures/${file.name}`);
            try {
                await uploadBytes(storageRef, file);
                const url = await getDownloadURL(storageRef);
                console.log(url);
                setProfilePictureUploaded(true);
                setProfilePictureLocal(url);
                setProfilePicture(user.username, url);
            } catch (error) {
                console.error("Error uploading file:", error);
                alert("Error uploading file");
            }
        }
    };

    return (
        <div className="main">
            <div className="sub">
                <div className="profile-info">
                    <div className="profile-header">
                        <div className="profile-picture">
                            <img
                                src={profilePictureLocal}
                                onClick={handleImageClick}
                                alt="Profile"
                            />
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                                accept=".png,.jpg,jpeg"
                            />
                        </div>
                        <div className="profile-username">{user.username}</div>
                    </div>
                    <div className="info-container">
                        <div className="info-name">
                            Namn: {user.first_name + " " + user.last_name}
                        </div>
                        <div className="info-email">Email: {user.email}</div>
                        <div className="info-role">
                            Role:{" "}
                            {permissionLevelText(user.permission_level_id)}
                        </div>
                    </div>
                </div>
            </div>
            {adminDiv}
        </div>
    );
}

export default Profile;
