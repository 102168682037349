import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { getAllDocuments, getDocumentById } from "./functions";
import { db } from "../firebase.config";

// TODO: check token before getting data

async function getAllGuides() {
    const queryResult = await getAllDocuments("guides");
    if (queryResult === null) {
        return null;
    }
    return queryResult;
}

async function getGuide(guideId) {
    const queryResult = await getDocumentById("guides", guideId);

    if (queryResult === null) {
        return null;
    }
    return queryResult;
}

async function saveNewGuide(title, body, author) {
    try {
        await setDoc(doc(db, "guides", title.toLowerCase()), {
            title: title,
            body: body,
            author: author,
            timestamp: serverTimestamp(),
        });
        console.log("Document successfully written!");
    } catch (error) {
        console.error("Error writing document: ", error);
    }
}

export { getGuide, saveNewGuide, getAllGuides };
