import React, { useContext, useState } from "react";
import MarkdownEditor from "@uiw/react-markdown-editor";
import "./css/new-guide.css";
import ImageUpload from "../components/ImageUpload";
import { UserContext } from "../App";
import { saveNewGuide } from "../utility/GuideFunctions";
import PermissionGuard from "../components/PermissionGuard";

function NewGuide() {
    PermissionGuard(2);
    const [markdown, setMarkdown] = useState("");
    const [title, setTitle] = useState("");

    const { user } = useContext(UserContext);

    function sumbitGuide() {
        saveNewGuide(title, markdown, user.username);

        setMarkdown("");
        setTitle("");
    }

    return (
        <div className="main">
            <div className="page-title">Stugan - Skapa ny guide</div>
            <div className="body">
                <div className="form-container">
                    <div className="sub-container">
                        <div className="guide-set-title">
                            <label htmlFor="guide-title-input">Titel: </label>
                            <div className="second-row-title">
                                <input
                                    id="guide-title-input"
                                    type="text"
                                    onChange={(event) =>
                                        setTitle(event.target.value)
                                    }
                                />
                                <input
                                    type="button"
                                    value="Ladda upp guide"
                                    className="sumbit-btn"
                                    onClick={sumbitGuide}
                                />
                            </div>
                        </div>
                        <MarkdownEditor
                            value={markdown}
                            height="300px"
                            onChange={(value, viewUpdate) => setMarkdown(value)}
                            visible={true}
                        />
                    </div>
                </div>

                <ImageUpload />

                <div className="instructions">
                    <h3>Intruktioner</h3>
                    <p>
                        För att skapa en ny guide så skriver man titel och
                        själva guiden.
                    </p>
                    <p>
                        Guiden skrivs i <b>markdown-format</b> i den stora
                        rutan. Önskar man att ha bilder med så behöver man ladda
                        upp dom först och sedan lägga in den tillhörande länken
                        i guiden.
                    </p>
                    <p>
                        Ny rad kan vara lite klurigt att få till i markdown, för
                        att göra en ny rad gör man 2st <i>mellanslag</i> följt
                        av <i>retur</i>.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default NewGuide;

/*

- Fixa uppladdning av bilder, samt ge länk till vart den befinner sig på sidan
- Fixa plats för vart bilder ska förvaras, lär hållas koll på i databasen
- 

*/
