import React, { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase.config";
import "./css/imageUpload.css";
import CopyButton from "./CopyButton";

const ImageUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            alert("Please select a file first!");
            return;
        }

        setUploading(true);

        const storageRef = ref(storage, `images/${selectedFile.name}`);
        try {
            await uploadBytes(storageRef, selectedFile);
            const url = await getDownloadURL(storageRef);
            setImageUrl(url);
        } catch (error) {
            console.error("Error uploading file:", error);
            alert("Error uploading file");
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="image-upload">
            <label htmlFor="file-upload" className="custom-file-upload">
                Välj ny bild
            </label>
            <span id="file-selected">
                {selectedFile ? selectedFile.name : ""}
            </span>
            .
            <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                className="file-input"
                accept=".png,.jpg,jpeg"
            />
            <button
                onClick={handleUpload}
                disabled={uploading}
                className="sumbit-input"
            >
                {uploading ? "Laddar upp..." : "Ladda upp"}
            </button>
            {imageUrl && (
                <div className="image-container">
                    <p>Bilden laddades upp!</p>
                    <img
                        src={imageUrl}
                        alt="Uploaded"
                        style={{ width: "300px" }}
                    />
                    <div className="image-url-container">
                        <CopyButton textToCopy={imageUrl} />
                        <span className="image-url">{imageUrl}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageUpload;
